import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import PageBuilder from '../../containers/PageBuilder/PageBuilder';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error,listings } = props;

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      options={{ listings:listings}}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { 
    pageAssetsData, 
    inProgress, 
    
    error } = state.hostedAssets || {};

    const {
      currentPageResultIds,
      pagination,
      queryInProgress,
      queryListingsError,
      queryParams,
    } = state.LandingPageReducer;
    const listings = getListingsById(state, currentPageResultIds);
  return { 
    pageAssetsData,
    inProgress,
    error,
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams, 
    listings
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
